.footer {
    background-color: #ffffff;
    color: #895e5e;
    /* padding: 2rem; */
    text-align: center;
    position: absolute;
    bottom: 0;
    font-weight: bold;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
  }
  
  .footer-section {
    flex: 1;
    padding: 1rem;
  }
  
  .footer-section h3 {
    margin-top: 0;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin: 0.5rem 0;
  }
  
  .footer-section ul li a {
    color: white;
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    border-top: 1px solid #444;
    padding-top: 1rem;
  }
  