body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  /* background-color: #f4f1eb; */
  background-color:#f9f9f9cc;
  color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  /* width:75vw; */
  height: 100vh;
  width:100vw;
}

.welcome{
  font-weight:500;
  font-style:italic;
  color:#000;
}
.username { 
  color: #cd0c39d6;
  font-weight:600;
  font-size:20px;

}
.App-header {
  background-color: #f4f1eb;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin:15px;
}

h2 { 
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

h1 {
  color: #ef4d80;
  margin:10px;
  font-size:78px;
}

.footerr  {
  left:0;
  bottom:0;
  right:0;

}
h3 { 
  color:#817571d1;
  font-style:normal;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.submit-module-input {
  width: 40%;
  padding: 10px;
 margin:10px;
}

.submit-button { 
  background-color: red;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22a6b3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;

}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.nav-links a {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.nav-links a:hover {
  color: #007BFF;
}

section {
  padding: 2rem;
  margin-top: 5rem; /* Adjust this value to avoid overlap with the fixed navbar */
  border: 1px solid #ddd;
}

.submit-module{
  display:block;
  padding: 5px;

  margin-left:auto;
  margin-right:auto;
  margin-bottom: auto;
}

.submit-text{
  width: 40%;
  padding: 10px;
 margin:10px;
}
.nav-bar {
  flex:1;
}
.result-module {
  display:flex;
  justify-content: space-around;
  /* margin-top:25px; */
  /* max-width:75vw; */
  margin-left:auto;
  margin-right:auto;
  margin-top:auto;
  margin-bottom:auto;
}

.without-summary-submit-module {
  margin-top:25%;
}
.with-summary-submit-module {
  margin-top:15%;
}
.summary-module {
  flex:1;
  margin-left:5%;
  margin-top:1%;
}

.related-videos-module { 
  flex:1;
  margin-top: 60px;
}

.current-video-module { 
  flex:1
}

.related-videos-button  {
  margin:10px;
}

.logo {
  height: 75px; /* Adjust based on your design */
  width: auto; /* Maintain aspect ratio */
  margin-right: 20px;
}


footer {
  background-color: #ffffff;
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  color:black;
  width: 100%;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  position:absolute;
  left:0;
  bottom:0;
  right:0;
}

.button-login {
  font-size: 12px;
}

.logout-button {
  margin:8px;

}
.content {
  flex: 1;
}

@import '../node_modules/@syncfusion/ej2-base/styles/material3.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material3.css';

/* Mobile-specific styles */
@media (max-width: 768px) {
  h1 {
    font-size:32px;
  }

  h2 { 
    font-size: 16px;
  }

  .without-summary-submit-module { 
    display:block;
    margin-top:65%;
  }
  .with-summary-submit-module { 
    display:block;
    margin-top:65%;
  }

  input {
    max-width:80%;
  }
  .footer {
    padding: 20px;
    text-align: center;
  }

  .spinner-container {
    width: 100%;
    height: 200px;
  }

  .result-module {
    flex-flow:column;

  }

  .summary-module {
    margin-left:0px;
    max-width:100vw;
  }

  .card { 
    max-width:100vw;
  }
  iframe {
    width: 100%;
    height: auto;
  }

  .related-videos-module { 
    max-width:100vw;
    margin: 35px;
  }
}
