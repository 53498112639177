/* src/SignUp.css */
.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }

.text-field-signup {
    width: 100%;
    padding: 10px;
   margin:10px;
  }
  
  
  .signup-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #ef4d80;
    font-style: bold;
    font-weight: 600;
  }
  
  .signup-container form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-container label {
    margin-bottom: 5px;
  }
  
  .signup-container input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .signup-container button {
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .signup-container button:hover {
    background-color: #0056b3;
  }
  