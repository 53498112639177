/* Timeline.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .stepContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 40px;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #4caf50;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
  }
  
  .stepNumber {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .stepContent {
    text-align: center;
  }
  
  .arrowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }
  
  .line {
    width: 4px;
    height: 40px;
    background: #4caf50;
  }
  
  .arrow {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #4caf50;
  }
  