.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgb(255, 255, 255) 0px 2px 0px inset, rgba(232, 234, 238, 0.3) 0px -2px 0px inset, rgba(223, 226, 231, 0.5) 0px 1px 2px 0px;
    padding: 16px;
    margin: 16px;
    max-width:50vw;
    transition: transform 0.2s;
    transition: transform 0.2s, max-height 0.2s ease-out;
    overflow: hidden;
    cursor: pointer;
    font-family: "IBM Plex Sans" "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight:400;
    max-height:600px;
    overflow-y:auto;
  }
/*  
  .card:hover {
    transform: scale(1.05);
  } */
  
  .card-title {
    font-size: 1.5em;
    margin-bottom: 8px;
    color: #ef4d80;
    font-weight:bold;
    transition: opacity 0.2s ease-out;
  }
  
  .card-content {
    color: #000;
    transition: opacity 0.2s ease-out;
    font-family: "IBM Plex Sans" "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    /* font-style: normal; */
  }

  @media (max-width: 768px) { 
   .card {

    max-width:100vw;
    }
  }