.videos-container { 
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px; 
}


.video-container { 
    flex: 1 1 calc(33.333% - 20px); /* 3 columns, subtracting the gap */
    /* background-color: #f1f1f1; */
    /* border: 1px solid #ddd; */
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    border-radius:2%;
}



.summarize-button { 
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    /* background-color: #007BFF; */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* background-color: tomato; */
    margin:10px;
    color:black;
}