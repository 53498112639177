.shiny-text {
    font-size: 1.3em;
    font-weight: bold;
    position: relative;
    display: block;
    color: #fff;
    background: linear-gradient(90deg, #f0f 0%, #0ff 50%, #f0f 100%);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 5s infinite linear;
    margin:32px;
  }
  
  @keyframes shine {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  