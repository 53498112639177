.about-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .title {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .text {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .highlight {
    color: #007BFF;
    font-weight: bold;
  }
  